<template>
    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 374.31 475.06">
        <g id="Layer_1-2" data-name="Layer_1">
            <g>
                <path class="fill-primary-950 dark:fill-white" d="M329.14,101.35c-3.17-1.04-4.83-5.36-4.19-10.64,3.31-15.29,14.73-44.7,16.64-60.97.49-4.16-1.02-7.98-8.56-10.87-34.59-13.27-140.27-9.21-160.22,3.06-5.5,3.38-6.9,5.4-9.9,12.65-5.33,13.94-9.66,34.05-13.74,48.05-3.34,9.75-13.01,8.65-13.46.46,2.91-18.43,9.21-63.04,27.11-73.17,22.91-12.97,161.5-12.9,188.17-1.68,16.06,6.76,3,25.52-.71,45.78-3.11,11.2-6.55,22.67-9.08,30.93-3.19,10.52-4.75,16.92-10.74,16.73l-1.31-.33Z"/>
                <path class="fill-primary-100 dark:fill-primary-400" d="M302.6,97.19c5.64-12.3,13.12-38.74,14.25-52.22.17-2.08-.5-6.75-2.13-8.05-11.89-9.55-45.07-8.94-60.32-9.33-17.49-.44-55.68-2.83-69.56,7.8-10.65,8.16-15.55,34.84-20.63,49.49-.71,4.13-2.69,7.04.31,8.79,9.63,1.01,68.59,3.01,105.74,4.75,17.37-.11,27,3.05,31.73-.2,0,0,.48-.76.61-1.03Z"/>
                <path class="fill-primary-100 dark:fill-primary-400" d="M125.29,115.42c-7.82,2.9-13.66,21.76-17.32,23.44,57.8-26.65,120.71,2.18,109.74,47.55-5.27,21.79-14.16,105.86-56.78,144.86-34.1,31.2-89.15,30.88-116.06-6.12-6.05,17.38-18.14,62.57-18.14,73.85,0,14.09,18.14,29.52,29.93,35.62,11.79,6.11,180.09,18.71,200.2,6.27,21.58-13.37,99.45-268.67,86.43-301.99-10.82-27.69-194.86-32.03-218-23.47Z"/>
                <path class="fill-primary-950 dark:fill-white" d="M206.95,474.68c-39.55-1.98-78.99-6.31-118.47-9.39-19.84-1.55-40.68-3.43-57.18-14.53-9.44-6.35-16.82-15.37-22.62-25.14-4.16-7.01-7.62-14.61-8.56-22.7-.15-1.28-.22-2.64.33-3.81,1.11-2.34,4.41-2.92,6.78-1.86,2.37,1.06,3.99,3.27,5.41,5.44,8.53,13.07,14.33,28.87,27.39,37.45,9.75,6.4,21.9,7.6,33.51,8.72,53.49,5.13,107.14,12.13,160.77,8.75,21.02-1.33,36.91-8.85,47.89-27.41,16.98-28.73,25.75-61.22,35.81-92.77,10.67-33.44,20.53-66.98,28.7-101.13,3.15-13.17,6.05-26.4,8.46-39.73,2.32-12.89,5.89-27.18,5.44-40.27-.3-8.74-3.29-17.77-9.88-23.53-5.05-4.42-11.91-6.72-16.11-11.94-.94-1.16-1.74-2.53-1.77-4.03-.04-2.67,2.53-4.81,5.18-5.2,2.65-.38,5.29.57,7.75,1.63,9.99,4.35,19.27,11.28,24.16,21,5.92,11.75,4.69,25.69,3,38.74-5.51,42.6-15.56,84.44-27.06,125.78-10.94,39.3-20.89,80.42-38.49,117.38-3.85,8.08-8.16,15.95-13.06,23.44-1.25,1.91-2.54,3.79-3.87,5.64-.93,1.29-1.89,2.55-2.87,3.79-20.13,25.29-50.48,27.18-80.61,25.67Z"/>
                <ellipse class="fill-primary-100 dark:fill-primary-400" cx="138.55" cy="239.68" rx="58.86" ry="38.56" transform="translate(-128.29 313.41) rotate(-75.41)"/>
                <path class="fill-primary-950 dark:fill-white" d="M122.61,421.76c-2.39-.01-4.77-.04-7.16-.07-25.73-.31-51.53-1.26-76.97-5.14-9.54-1.45-19.1-3.34-27.98-7.1-3.98-1.68-8.11-4.08-9.59-8.13-.77-2.09-.72-4.39-.56-6.61.66-9.24,3.12-18.34,7.21-26.65,1.27-2.58,3.2-5.42,6.08-5.52,2.29-.08,4.3,1.76,5.11,3.89.81,2.14.65,4.51.33,6.77-.49,3.58-1.33,7.12-2.51,10.54-1.05,3.06-2.38,6.37-1.3,9.42,1.22,3.46,5.03,5.2,8.55,6.23,6.54,1.92,13.35,2.68,20.13,3.37,37.57,3.81,76.82,7.31,114.49,4.28,10.31-.83,20.6-2.31,30.64-4.85,5.86-1.49,11.56-3.48,17.21-5.61,13.94-5.24,20.39-19.72,26.23-32.43,7.11-15.47,13.05-31.49,18.6-47.58,6.51-18.87,12.39-37.95,17.88-57.13,5.49-19.18,10.57-38.47,15.21-57.87,5.59-23.36,11.12-46.99,14.84-70.73.75-4.79,1.38-9.8-.15-14.39-3.42-10.25-16-9.51-24.74-10.22-11.06-.9-22.17-.63-33.25-1.06-18.16-.71-36.34-1-54.51-.87-18.53.13-37.1.7-55.48,3.07-7.62.98-15.51,2.4-21.69,6.95-7.18,5.29-10.91,13.93-14.32,22.15-5.06,12.19-10.12,24.38-15.17,36.56-1.12,2.7-2.31,5.49-4.52,7.4-2.21,1.91-5.76,2.61-8.04.78-1.42-1.15-2.06-3.01-2.39-4.81-1.15-6.21.46-12.57,2.38-18.58,3.54-11.1,8.19-21.85,13.85-32.04,4.32-7.77,9.37-15.36,16.46-20.73,6.82-5.17,15.13-7.99,23.45-10.04,19.33-4.77,39.38-5.85,59.29-6.25,30.82-.62,61.68.34,92.37,3.23,13.61,1.28,28.99,4.01,36.38,15.49,5.05,7.85,4.45,16.94,3.09,25.75-4.58,29.77-10.81,59.02-18.29,88.2-10.09,39.35-21.53,78.5-35.5,116.67-7.26,19.86-14.64,41.7-27.31,58.92-10.75,14.62-31.88,18.89-48.73,21.41-19.71,2.95-39.68,3.44-59.6,3.32ZM14.29,398.75h.14-.14ZM27.24,327.44c-.66,0-1.34-.1-2.01-.29-3.8-1.1-5.97-5.08-4.86-8.86,2.59-8.79,5.3-17.89,8.05-27.04l1.34-4.46c1.14-3.77,5.13-5.9,8.92-4.77,3.78,1.14,5.93,5.12,4.78,8.9l-1.34,4.45c-2.74,9.11-5.44,18.18-8.01,26.94-.92,3.11-3.78,5.13-6.87,5.13Z"/>
                <path class="fill-primary-950 dark:fill-white" d="M98.7,363.69c-106.74-12.58-79.91-192.13,9.27-220.05,82.91-24.6,86.15,68.66,61.02,123.59-48.16,121.62-133.74,14.09-80.66-63.81,10.14-17.97,32.04-26.19,12.68,7.09-44.46,74.53,26.28,150.6,59.95,36.09,15.92-43.84,9.27-108.82-51.52-87.6-76.46,25.76-98.65,197.39,3.46,188.54,69.58-10.31,95.37-92.76,97.79-152.47,1.8-25.68,12-26.67,13.82-8.3,3.51,69.42-35.84,176.4-123.26,177l-2.55-.08Z"/>
            </g>
        </g>
    </svg>
</template>
