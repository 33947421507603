<template>
</template>

<script setup>
import { onMounted, watch } from 'vue'
import { useToast } from '@/composables/useToast.js'

const props = defineProps({
    swalErrors: Object,
    swalFlash: Object,
})

onMounted(() => {
    if(props?.swalFlash && props?.swalFlash?.icon) {
        useToast(props.swalFlash)
    }

    if(Object.keys(props?.swalErrors).length) {
        useToast({ icon: 'error', html: Object.values(props?.swalErrors).join('<br/>') })
    }
})

watch(() => props.swalErrors, (errors) => Object.keys(errors).length && useToast({ icon: 'error', html: Object.values(errors).join('<br/>') }))
watch(() => props.swalFlash, (swal) => swal && swal?.icon && useToast(swal))
</script>
